import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// components
import Navigation from "./Components/navbar";
import Footer from "./Components/footer";

// pages

const Home = lazy(() => import("./Pages/Home"));
const Category = lazy(() => import("./Pages/Category"));
const Customize = lazy(() => import("./Pages/Customize"));
const EventDetails = lazy(() => import("./Pages/Eventdetails"));
const Addguests = lazy(() => import("./Pages/Addguests"));
const Review = lazy(() => import("./Pages/Review"));
const AboutComponent = lazy(() => import("./Pages/About"));
const Contact = lazy(() => import("./Pages/Contact"));
const Termsandservice = lazy(() => import("./Pages/termsandservice"));
const Privacypolicy = lazy(() => import("./Pages/Privacypolicy"));
const CSR = lazy(() => import("./Pages/CSR"));
const Careers = lazy(() => import("./Pages/Careers"));
const Signin = lazy(() => import("./Pages/Signin"));
const Signup = lazy(() => import("./Pages/Signup"));

const DraftListPage = lazy(() => import("./Pages/DraftList"));
const ReviewListPage = lazy(() => import("./Pages/ReviewList"));

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <main className="main-content">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cards/:type/:id" element={<Category />} />
              <Route path="/cards/draft-list/:id" element={<Category />} />

              <Route path="/contact" element={<Contact />} />
              <Route path="/customize/:id" element={<Customize />} />
              <Route path="/customize/:type/:id" element={<Customize />} />

              <Route
                path="/event-details/:type/:id"
                element={<EventDetails />}
              />
              <Route path="/addguests/:id" element={<Addguests />} />
              <Route path="/review/:type/:id" element={<Review />} />
              <Route path="/about" element={<AboutComponent />} />
              <Route path="termsandservice" element={<Termsandservice />} />
              <Route path="privacypolicy" element={<Privacypolicy />} />
              <Route path="csr" element={<CSR />} />
              <Route path="careers" element={<Careers />} />
              <Route path="signin" element={<Signin />} />
              <Route path="signup" element={<Signup />} />
              <Route path="/draft-list" element={<DraftListPage />} />
              <Route path="/review-list" element={<ReviewListPage />} />

              {/* Define other routes that you need*/}
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
