import React from "react";
import "../styles/components/Footer.css";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";



import { Link } from "react-router-dom";
import logo from '../assets/logo.png'


export default function Footer() {
  return (
    <>
      <footer className="footer_container bg-2 pt-5 position-relative">
        <div className="container">
          <div className="row gy-4">
            <div className="footer-logo col-12 col-md">
              <div style={{ height: "7rem", width: "7rem" }}>
                <img
                  style={{ filter: "invert(1) contrast(0.7)" }}
                  className="d-block h-100 w-100"
                  src={logo}
                  alt="..."
                />
              </div>
              <div className="footer-links mt-5">
                <h5 className="text-white">Follow us on</h5>
                <ul className="nav gap-3 bg-transparent">
                  <li>
                    <a
                      href="http://www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-links col-12 col-md">
              <ul className="nav flex-column gap-2 bg-transparent">
                <li>
                  <h4 className="text-white">Company</h4>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/hiredesigners">HireDesigners</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </div>

            <div className="footer-links col-12 col-md">
              <ul className="nav flex-column gap-2 bg-transparent">
                <li>
                  <h4 className="text-white">Terms</h4>
                </li>
                <li>
                  <Link to="/privacyPolicy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/termsandservice">Terms and Service</Link>
                </li>
                <li>
                  <Link to="/csr">CSR</Link>
                </li>
              </ul>
            </div>

            <div className="footer-links col-12 col-md-4">
              <h4 className="text-white">Subscribe to our newsletter</h4>
              <p className="text-white text-opacity-75">
                Monthly digest of whats new and exciting from us.
              </p>
              <div className="d-flex w-100 gap-2">
                <label htmlFor="newsletter1" className="visually-hidden">
                  Email address
                </label>
                <input
                  id="newsletter1"
                  type="text"
                  className="form-control"
                  placeholder="Email address"
                />
                <button className="btn-1" type="button">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top border-secondary mt-5 py-3 text-center">
          <span className="text-white text-opacity-75">
          @2024 tekido.com.Inc. All rights reserved
          <br/>
          Made By <a href="https://catchyad.com/">Catchy Ad</a>
          </span>
        </div>
      </footer>
    </>
  );
}
