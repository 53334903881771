import { createSlice } from "@reduxjs/toolkit";

const token = JSON.parse(localStorage.getItem("token"));
const initialUser = () => {
  const item = window.localStorage.getItem("token");
  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialState = {
  user: null,
  isLoading: false,
  isAuthenticated: false,
  token: initialUser(),
};

export const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    login: (state, action) => {
      console.log(action);
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.token = action.payload.authtoken;
    },

    signUp: (state, action) => {
      console.log(action);
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.token = action.payload.authtoken;
    },

    isLoggedIn: (state) => {
      if (token) {
        state.user = token.user;
        state.isAuthenticated = true;
        state.token = token.authtoken;
      }
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, isLoggedIn, logout, signUp } = userSlice.actions;

export default userSlice.reducer;
